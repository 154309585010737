export const MENU = [
	{
		label: "about",
		key: "about",
		link: "/about"
	},
	{
		label: "contact us",
		key: "contact",
		link: "/contact-us"
	},
	{
		label: "blog",
		key: "blog",
		link: "/blog"
	},
	{
		label: "webinars",
		key: "webinars",
		link: "/webinars/all"
	},
	{
		label: "Sign in",
		key: "sign-in",
		link: "https://app.uprise.co"
	},
	{
		label: "book a demo",
		key: "demo",
		link: "/book-demo"
	},
	{
		label: "careers",
		key: "careers",
		link: "/careers"
	},
	{
		label: "pricing",
		key: "pricing",
		link: "/pricing"
	}
]

export const LEGAL_INFO = [
	{
		label: "privacy",
		key: "privacy",
		link: "/privacy"
	},
	// {
	// 	label: "California Privacy Policy",
	// 	key: "california-privacy-policy",
	// 	link: "/privacy-us/#california"
	// },
	{
		label: "terms",
		key: "terms",
		link: "/terms"
	},
	{
		label: "cookie policy",
		key: "cookie-policy",
		link: "/cookie-policy"
	},
	{
		label: "data security",
		key: "data-security",
		link: "/data-security/"
	}
]

export const SOCIAL_LINKS = [
	{
		label: require("../../images/facebook@3x.png"),
		key: "facebook",
		link: "https://www.facebook.com/getuprise/"
	},
	{
		label: require("../../images/linkedin@3x.png"),
		key: "linkedIn",
		link: "https://www.linkedin.com/company/getuprise/"
	},
	{
		label: require("../../images/twitter@3x.png"),
		key: "twitter",
		link: "https://twitter.com/upriseatwork?lang=en"
	}
]

export function toCapitalCase(str) {
	return `${str[0].toUpperCase()}${str.slice(1)}`
}

export const ALL_IN_ONE_PLATFORM = [
	{
		label: "Capability Building",
		key: "capability_building",
		link: "/capability-building"
	},
	{
		label: "Employee Assistance Program",
		key: "assitance_program",
		link: "/employee-assistance-program"
	},
	{
		label: "Cultural Tools",
		key: "cultural_tools",
		link: "/culture-tools"
	},
	{
		label: "Human Resources Tools",
		key: "hr_tools",
		link: "/hr-tools"
	}
]

export const ABOUT_UPRISE = [
	{
		label: "Our Story",
		key: "about",
		link: "/about"
	},
	{
		label: "Webinars",
		key: "webinars",
		link: "/webinars/all"
	},
	{
		label: "Blog",
		key: "blog",
		link: "/blog"
	},
	{
		label: "Careers",
		key: "careers",
		link: "/careers"
	},
	{
		label: "Contact Us",
		key: "contact_us",
		link: "/contact-us"
	}
]

export const PRODUCT_PRICING = [
	{
		label: "Case Studies",
		key: "case_studies",
		link: "/case-studies"
	},
	{
		label: "Compare",
		key: "compare",
		link: "/compare"
	},
	{
		label: "Pricing",
		key: "pricing",
		link: "/pricing"
	},
	{
		label: "Product Tour",
		key: "product-tour",
		link: "/product-tour"
	}
]
