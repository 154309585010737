import React from "react"
import Styled from "styled-components"
// import Img from 'gatsby-image'
import ReactMarkdown from "react-markdown"
import format from "date-fns/format"

// import RelatedPosts from "./RelatedPosts"
import WebinarHome from "./WebinarHome"
import {ButtonStandard} from "../Button"

import {getDateWithoutTime, normalizeImageUrl} from "@utils"
import {Heading1, BodyText, ContentWrapper} from "@styles/text"
import {SectionWrapper, Row, Col} from "@styles/grid"
import {backgrounds} from "@constants/colors"
import {useJSONLD} from "@utils/jsonld"

const WebinarContentContainer = Styled.div`
  background-color: ${backgrounds.white};
  padding: 80px 0px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(219, 221, 227, 0.8);

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    & h1 {
      padding: 0px 8%;
    }

    & .media {
      width: 100%;
      max-width: 700px;
    }
  }
`

const VideoDesc = Styled.div`
  padding: 0 15px;
`

const ButtonWrap = Styled.div`
	display: flex;
	flex-direction: row
`

const VideoPlayerWrapper = Styled.div`
	width: 100%;

	iframe {
		width: 100%
	}
`

function Webinar({title, date, content, media, slug, vimeoId, youtubeId, category = "Category 1", action}) {
	useJSONLD("BLOG", {
		name: title,
		author: "Charlotte",
		datePublished: date,
		articleImage: normalizeImageUrl(media),
		articleSection: category,
		articeBody: content.original,
		url: `https://uprise.co/webinars/${slug}`,
		publisher: "Team Uprise"
	})
	const vimeoURL = "https://player.vimeo.com/video/"
	const youtubeURL = "https://www.youtube.com/embed/"
	const url = vimeoId
		? `${vimeoURL + vimeoId}?autoplay=0&loop=0&autopause=0`
		: youtubeId
		? youtubeURL + youtubeId
		: null
	const paddingVertical = 100

	return (
		<SectionWrapper
			containerFluidProps={{
				style: {
					backgroundColor: backgrounds.fadedPurple
				}
			}}
			containerProps={{
				style: {
					paddingTop: paddingVertical,
					paddingBottom: paddingVertical
				}
			}}>
			<WebinarContentContainer>
				<Row className='justify-content-center'>
					<Col className='col-lg-9 content'>
						<Heading1 textCenter>{title}</Heading1>
						<BodyText textCenter className='mgn-t-20 heading-meta'>
							{format(new Date(getDateWithoutTime(date)), "MMMM dd, yyyy")}
							&nbsp; | &nbsp;
							{category}
						</BodyText>

						<VideoPlayerWrapper className='video-player'>
							<iframe
								title='uprise tour video'
								src={url}
								width='720'
								height='480'
								frameBorder='0'
								allow='autoplay; fullscreen'
								allowFullScreen></iframe>
						</VideoPlayerWrapper>

						<ContentWrapper>
							<VideoDesc>
								<ReactMarkdown source={content.original} />
							</VideoDesc>
						</ContentWrapper>

						<ButtonStandard
							link={action[0].link}
							title={action[0].label || action[0].title}
							size={action[0].size}
							variant={action[0].variant}
						/>
					</Col>
				</Row>
			</WebinarContentContainer>
		</SectionWrapper>
	)
}

export default Webinar

export {WebinarHome}
