import React from "react"
import PropTypes from "prop-types"
import Styled from "styled-components"
import {useStaticQuery, graphql} from "gatsby"
import {navigateTo, VideoPlayerContext} from "@utils"
import {Button} from "@uprise/button"

const ButtonWrapper = Styled.div`
  display: flex;
  justify-content: ${props => props.alignHorizontal};
`

export const ButtonStandard = ({
	variant = "primary",
	label,
	title,
	link,
	fullWidth,
	white,
	alignHorizontal = "center",
	size = "large",
	className,
	paddingLeft,
	paddingRight,
	videoURL
}) => {
	const {togglePlayVideo, setURL} = React.useContext(VideoPlayerContext)

	console.log("xxxxxx")
	console.log(variant)
	console.log(size)
	console.log("xxxxxx")

	// fix old prop values from legacy component
	if (variant === "PRIMARY") {
		variant = "primary"
	} else if (variant === "OUTLINE") {
		variant = "secondary"
	}

	if (size !== "large" || size !== "medium" || size !== "small" || size !== "tiny") {
		size = "large"
	}

	return (
		<ButtonWrapper alignHorizontal={alignHorizontal}>
			<Button
				className={className}
				onClick={() =>
					navigateTo(link, {
						fn: () => {
							if (videoURL) {
								setURL(videoURL)
								togglePlayVideo()
							}
						}
					})
				}
				transparent={white}
				paddingLeft={paddingLeft}
				paddingRight={paddingRight}
				title={title ? title : label}
				fullWidth={fullWidth}
				size={size}
				variant={variant}
			/>
		</ButtonWrapper>
	)
}

ButtonStandard.propTypes = {
	variant: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	alignHorizontal: PropTypes.string.isRequired,
	size: PropTypes.string.isRequired
}
ButtonStandard.defaultProps = {
	label: "button",
	alignHorizontal: "center",
	size: "large",
	variant: "primary"
}
