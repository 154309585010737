import React from "react"

import FeaturedWebinarCard from "./FeaturedWebinarCard"
import CategoryMenu from "./CategoryMenu"
import CategorySection from "./CategorySection"
import {useWebinarPostData} from "./utils"

function WebinarHome() {
	const [categories, categoryMap, featuredWebinarPost] = useWebinarPostData()

	return (
		<React.Fragment>
			<FeaturedWebinarCard {...featuredWebinarPost} />
			<CategoryMenu categories={["All categories", ...categories]} activeCategory={"All categories"} />
			{categories.map((category, idx) => {
				const blogs = categoryMap[category]
				const showAll = blogs.length >= 6
				return (
					<CategorySection
						key={`category-section-${idx}`}
						title={category}
						blogs={blogs.slice(0, 6)}
						showAll={showAll}
					/>
				)
			})}
		</React.Fragment>
	)
}

export default WebinarHome
