import React from "react"
import Components from "../components.js"
import "@styles/layout.css"
import "@styles/margins.css"
import {useJSONLD} from "@utils/jsonld"

function Page(props) {
	useJSONLD("HOME")
	return (
		<React.Fragment>
			{React.createElement(Components("seo"), {
				key: props.title,
				blok: {
					lang: "en",
					keywords: [],
					meta: {},
					title: props.title
				}
			})}
			{props.blok.body &&
				props.blok.body.map(blok =>
					React.createElement(Components(blok.component), {
						key: blok._uid,
						blok: blok,
						...blok
					})
				)}
		</React.Fragment>
	)
}

export default Page
