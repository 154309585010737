import React, {Component} from "react"
import Loadable from "@loadable/component"
import {Page, Grid, Column, ComponentNotFound} from "./UILayout"
const HeroBanner = Loadable(() => import("./HeroBanner"))
const MultipleMediaWithCaption = Loadable(() => import("./MultipleMediaWithCaption"))
const SingleMediaWithCaptionAndParagraph = Loadable(() => import("./SingleMediaWithCaptionAndParagraph"))
const HeadingWithSingleMediaAndButton = Loadable(() => import("./HeadingWithSingleMediaAndButton"))
const Media = Loadable(() => import("./Media"))
const RichText = Loadable(() => import("./RichText"))
const UpriseReport = Loadable(() => import("./UpriseReport"))
const Heading = Loadable(() => import("./Heading"))
import Seo from "./Seo"
const Header = Loadable(() => import("./Header"))
const Footer = Loadable(() => import("./Footer"))
const List = Loadable(() => import("./List"))
const SupportCard = Loadable(() => import("./SupportCard"))
const ContactBanner = Loadable(() => import("./ContactBanner"))
const BlogPost = Loadable(() => import("./Blog"))
const FeaturePricingCard = Loadable(() => import("./FeaturePricingCard"))
const FAQs = Loadable(() => import("./FAQs"))
const FeatureCard = Loadable(() => import("./FeatureCard"))
const StaffMemberCard = Loadable(() => import("./StaffMemberCard"))
const Subscribe = Loadable(() => import("./Subscribe"))
const ContactForm = Loadable(() => import("./ContactForm"))
const Static = Loadable(() => import("./Static"))
import Plugin, {PaperForm, Code} from "./Plugin"
const Path = Loadable(() => import("./Path"))
const CollateralCard = Loadable(() => import("./CollateralCard"))
const Carousel = Loadable(() => import("./Carousel"))
const CalendarCard = Loadable(() => import("./CalendarCard"))
const BlogHome = Loadable(() => import("./Blog/BlogHome"))
import {RequestAccessCode, LegalSupport, EapSupportForm} from "./Forms"
import {ButtonStandard, ButtonImage} from "./Button"
const PricingTable = Loadable(() => import("./PricingTable"))
const PricingFeaturesTable = Loadable(() => import("./PricingTable/PricingFeaturesTable"))
const PricingFeaturesTableSME = Loadable(() => import("./PricingTable/PricingFeaturesTableSME"))
import {SECTION_TYPES, FORM_TYPES, CARD_TYPES, LAYOUT_TYPES} from "@constants"
const RelatedPosts = Loadable(() => import("./Blog/RelatedPosts"))
const TrainingCard = Loadable(() => import("./TrainingCard"))
const Testimony = Loadable(() => import("./Testimony"))
const FaqCard = Loadable(() => import("./FaqCard"))
import {Redirect} from "./Generic"
const PricingCard = Loadable(() => import("./PricingCard"))
const UpdatePricingCard = Loadable(() => import("./PricingCard/UpdatePricingCard"))
const MiniStepCard = Loadable(() => import("./MiniStepCard"))
const EmailGate = Loadable(() => import("./PricingGate"))
const TestimonyWithImage = Loadable(() => import("./TestimonyWithImage"))
const FeaturedBlogSection = Loadable(() => import("./FeaturedBlogSection"))
const AnnouncementPopup = Loadable(() => import("./AnnouncementPopup"))
import WebinarPost, {WebinarHome} from "./Webinar"
const Spacer = Loadable(() => import("./Spacer"))
const MobileScreenVideoPlayer = Loadable(() => import("./mobileScreenVideoPlayer"))
const HowItWorksTimelineElement = Loadable(() => import("./HowItWorksTimelineElement"))
const TimelineDescription = Loadable(() => import("./HowItWorksTimelineElement/TimelineDescription"))
const TimelineWorkFlowCard = Loadable(() => import("./HowItWorksTimelineElement/TimelineWorkFlowCard"))
const CardWithImageTitleAndButton = Loadable(() => import("./HowItWorksTimelineElement/CardWithImageTitleAndButton"))
const ExperienceCard = Loadable(() => import("./HowItWorksTimelineElement/ExperienceCard"))
const ComponentList = {
	[SECTION_TYPES.REPORT]: UpriseReport,
	[SECTION_TYPES.HEADING]: Heading,
	[SECTION_TYPES.SEO]: Seo,
	[SECTION_TYPES.HEADER]: Header,
	[SECTION_TYPES.FOOTER]: Footer,
	[SECTION_TYPES.LIST]: List,
	[SECTION_TYPES.SUBSCRIBE]: Subscribe,
	[SECTION_TYPES.PATH]: Path,
	[SECTION_TYPES.CAROUSEL]: Carousel,
	[SECTION_TYPES.PAPER_FORM]: PaperForm,
	[SECTION_TYPES.PAGE]: Page,
	[SECTION_TYPES.GRID]: Grid,
	[SECTION_TYPES.COLUMN]: Column,
	[SECTION_TYPES.HERO_BANNER]: HeroBanner,
	[SECTION_TYPES.MEDIA]: Media,
	[SECTION_TYPES.MULTIPLE_MEDIA_WITH_CAPTION]: MultipleMediaWithCaption,
	[SECTION_TYPES.SINGLE_MEDIA_WITH_CAPTION_AND_PARAGRAPH]: SingleMediaWithCaptionAndParagraph,
	[SECTION_TYPES.HEADING_WITH_SINGLE_MEDIA_AND_BUTTON]: HeadingWithSingleMediaAndButton,
	[SECTION_TYPES.CONTACT_BANNER]: ContactBanner,
	[SECTION_TYPES.BLOG_POST]: BlogPost,
	[SECTION_TYPES.CONTACT_FORM]: ContactForm,
	[SECTION_TYPES.FEATURE_PRICING_CARD]: FeaturePricingCard,
	[SECTION_TYPES.WITH_ACCORDION]: FAQs,
	[SECTION_TYPES.STAFF_MEMBER]: StaffMemberCard,
	[SECTION_TYPES.STATIC]: Static,
	[SECTION_TYPES.PLUGIN]: Plugin,
	[SECTION_TYPES.COLLATERAL_CARD]: CollateralCard,
	[SECTION_TYPES.CALENDAR_CARD]: CalendarCard,
	[SECTION_TYPES.BLOG_HOME]: BlogHome,
	[SECTION_TYPES.REQUEST_ACCESS_CODE]: RequestAccessCode,
	[SECTION_TYPES.LEGAL_SUPPORT]: LegalSupport,
	[SECTION_TYPES.BUTTON]: ButtonStandard,
	[SECTION_TYPES.BUTTON_IMAGE]: ButtonImage,
	[SECTION_TYPES.PRICING_TABLE]: PricingTable,
	[SECTION_TYPES.PRICING_FEATURES_TABLE]: PricingFeaturesTable,
	[SECTION_TYPES.PRICING_FEATURES_TABLE_SME]: PricingFeaturesTableSME,
	[SECTION_TYPES.RELATED_POST]: RelatedPosts,
	[SECTION_TYPES.TRAINING_CARD]: TrainingCard,
	[SECTION_TYPES.TESTIMONY_CARD_WITH_IMAGE]: TestimonyWithImage,
	[SECTION_TYPES.TESTIMONY_CARD]: Testimony,
	[SECTION_TYPES.FAQ_CARD]: FaqCard,
	[SECTION_TYPES.REDIRECT]: Redirect,
	[SECTION_TYPES.CODE]: Code,
	[SECTION_TYPES.PRICING_CARD]: PricingCard,
	[SECTION_TYPES.UPDATE_PRICING_CARD]: UpdatePricingCard,
	[SECTION_TYPES.MINI_STEP_CARD]: MiniStepCard,
	[SECTION_TYPES.EMAIL_GATE]: EmailGate,
	[SECTION_TYPES.FEATURED_BLOG_SECTION]: FeaturedBlogSection,
	[SECTION_TYPES.ANNOUNCEMENT_POPUP]: AnnouncementPopup,
	[SECTION_TYPES.WEBINAR_POST]: WebinarPost,
	[SECTION_TYPES.WEBINAR_HOME]: WebinarHome,
	[SECTION_TYPES.RICH_TEXT]: RichText,
	[SECTION_TYPES.MOBILE_VIDEO_PLAYER]: MobileScreenVideoPlayer,
	[SECTION_TYPES.HOW_IT_WORKS_TIMELINE_ELEMENT]: HowItWorksTimelineElement,
	[SECTION_TYPES.TIMELINE_DESCRIPTION]: TimelineDescription,
	[SECTION_TYPES.TIMELINE_WORKFLOW_CARD]: TimelineWorkFlowCard,
	[SECTION_TYPES.CARD_WITH_IMAGE_TITLE_AND_BUTTON]: CardWithImageTitleAndButton,
	[SECTION_TYPES.ORGANISATION_EXPERIENCE_CARD]: ExperienceCard,
	// Layout
	[LAYOUT_TYPES.SPACER]: Spacer,
	// Cards
	[CARD_TYPES.SUPPORT_CARD]: SupportCard,
	[CARD_TYPES.FEATURE_CARD]: FeatureCard,
	// Forms
	[FORM_TYPES.EAP_SUPPORT_FORM]: EapSupportForm
}

const Components = type => {
	if (typeof ComponentList[type] === "undefined") {
		return ComponentNotFound
	}

	return ComponentList[type]
}

export default Components
