import {useStaticQuery, graphql} from "gatsby"

export function useFooter() {
	const data = useStaticQuery(graphql`
		{
			allStoryBlokEntry: allStoryblokEntry(filter: {full_slug: {eq: "home"}}) {
				edges {
					node {
						id
						name
						full_slug
						published_at(fromNow: true)
						slug
						field_component
						content
					}
				}
			}
		}
	`)
	// Get footer
	const {allStoryBlokEntry} = data
	const {node} = allStoryBlokEntry.edges.find(({node}) => node.field_component === "page")
	const content = JSON.parse(node.content)
	const footer = content.body.find(item => item.component === "FOOTER")
	return footer
}
