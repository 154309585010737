import React from "react"
import PropTypes from "prop-types"
import Styled from "styled-components"

const UILayoutWrapper = Styled.div``

function UILayout() {
	return <UILayoutWrapper></UILayoutWrapper>
}

export default UILayout

UILayout.propTypes = {
	title: PropTypes.string.isRequired
}
UILayout.defaultTypes = {
	title: PropTypes.string.isRequired
}
