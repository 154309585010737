import React from "react"
import {Helmet} from "react-helmet"
import {normalizeImageUrl} from "@utils"

function Seo({blok}) {
	const {lang = "en", keywords = [], meta = {}} = blok

	const metatitle = meta.title ? meta.title : blok.title ? blok.title : "Uprise Employee Assistance"

	const metadescription = meta.description
		? meta.description
		: "Uprise is a proactive and science-based Employee Assistance Program (EAP) provider in Australia."

	const ogDescription = meta.og_description
		? meta.og_description
		: "Uprise is a proactive and science-based Employee Assistance Program (EAP) provider in Australia."

	const ogImage = meta.og_image
		? meta.og_image
		: "https://a.storyblok.com/f/65490/1200x627/b807507484/uprise-og-image.png"

	const ogTitle = meta.og_title ? meta.og_title : "Uprise Employee Assistance"
	const twitterDescription = meta.twitter_description
		? meta.twitter_description
		: "Uprise is a proactive and science-based Employee Assistance Program (EAP) provider in Australia."
	const twitterImage = meta.twitter_image
		? meta.twitter_image
		: "https://a.storyblok.com/f/65490/1200x627/b807507484/uprise-og-image.png"
	const twitterTitle = meta.twitter_title ? meta.twitter_title : "Uprise Employee Assistance"
	let {
		description = metadescription,
		og_description = ogDescription,
		og_image = ogImage,
		og_title = ogTitle,
		title = metatitle,
		twitter_description = twitterDescription,
		twitter_image = twitterImage,
		twitter_title = twitterTitle
	} = meta

	og_image = normalizeImageUrl(og_image)

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={title}
			meta={[
				{
					name: `description`,
					content: description
				},
				{
					property: `og:title`,
					content: og_title
				},
				{
					property: `og:description`,
					content: og_description
				},
				{
					property: `og:image`,
					content: ogImage
						? ogImage
						: "https://a.storyblok.com/f/65490/1200x627/b807507484/uprise-og-image.png"
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`
				},
				{
					name: `twitter:image`,
					content: "https://a.storyblok.com/f/65490/1200x627/b807507484/uprise-og-image.png"
				},
				{
					name: `twitter:title`,
					content: twitter_title
				},
				{
					name: `twitter:description`,
					content: twitter_description
				}
			]
				.concat(
					keywords.length > 0
						? {
								name: `keywords`,
								content: keywords.join(`, `)
						  }
						: []
				)
				.concat(meta)}
		/>
	)
}

export default Seo
