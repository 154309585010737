import React from "react"
import PropTypes from "prop-types"
import Styled from "styled-components"
import {navigateTo} from "@utils"
import Img from "gatsby-image"
import {getFixedGatsbyImage} from "gatsby-storyblok-image"

const ButtonImageWrapper = Styled.div`
  display: flex;
  justify-content: ${props => props.alignHorizontal};
`

export const ButtonImage = ({link, image, altText, width, height, className, alignHorizontal}) => {
	const fixedProps = getFixedGatsbyImage(image, {
		width: width,
		toFormat: "webp"
	})

	return (
		<ButtonImageWrapper
			alignHorizontal={alignHorizontal}
			onClick={() =>
				navigateTo(link, {
					fn: () => {
						if (videoURL) {
							setURL(videoURL)
							togglePlayVideo()
						}
					}
				})
			}>
			<Img
				className={className}
				fixed={fixedProps}
				alt={altText}
				imgStyle={{objectFit: "contain", height: height, width: width}}
				objectFit='cover'
				objectPosition='50% 50%'
			/>
		</ButtonImageWrapper>
	)
}

ButtonImage.propTypes = {
	link: PropTypes.string.isRequired
}
ButtonImage.defaultProps = {
	label: "button"
}
