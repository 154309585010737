import React from "react"
import PropTypes from "prop-types"
import Styled from "styled-components"
import {navigate} from "gatsby"
import {Col} from "@styles/grid"
import {RoundButton} from "@styles/buttons"
import {getSlugFromTitle} from "@utils"

const CategoryMenuContainer = Styled.div`

  & .content {
	position: relative;
	z-index: 100;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

	@media screen and (min-width: 768px) {
		padding-top: 48px;
	}

    & button {
      margin-right: 12px;
      margin-bottom: 24px;
    }

    @media (min-width: 768px) {
      padding-bottom: 50px;
      flex-direction: row;
    }
  }
`

const CategoryFilter = Styled.div`
  background: #FFFFFF;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 1px 5px 5px rgba(0,0,0,0.05);
//   margin-top: 2rem;
//   margin-bottom: 2rem;
  font-family: 'Proxima Nova';

  .category-name {
	position: relative;
  padding: 5px;
  
  @media (max-width: 1024px) {
	  &:after {
		  content: ' ';
		  position: absolute;
		  top: 10px;
		  right: 20px;
		  display: inline-block;
		  height: 14px;
		  width: 14px;
		  background-image: url(${require("../../images/icons/uprise-dropdown-theme-color.svg")});
		  background-size: contain;
		  background-repeat: no-repeat;
	  }
  }

  .cat-name {
	  margin-left: 5px;
	  font-family: 'Proxima Nova Semibold';

  }
`

const CategoryFilterItem = Styled.div`
  margin-top: 1rem;
  border-top: 1px solid rgba(0,0,0,0.05);
`

const CategoryFilterMenuItem = Styled.div`
	border-radius: 10px;
	padding: 1rem;
	margin: 0.5rem auto;
	
	&:hover {
		background: rgba(0,0,0,0.05);
	}
`

function CategoryMenu({context, categories, activeCategory}) {
	const hasWindow = () => typeof window !== "undefined"
	const [showCategoryFilter, setShowCategoryFilter] = React.useState(false)
	const [width, setWidth] = React.useState(hasWindow() && window.innerWidth)
	const [categoryTitle, setCategoryTitle] = React.useState("")
	const menu = categories.map((cat, index) => ({
		id: index,
		slug: getSlugFromTitle(cat),
		title: cat,
		isActive: cat === activeCategory
	}))

	const whaWindowResize = () => {
		if (hasWindow()) setWidth(window.innerWidth)
	}

	React.useEffect(() => {
		if (hasWindow()) {
			window.addEventListener("resize", whaWindowResize)
		}

		return () => {
			if (hasWindow()) {
				window.addEventListener("resize", whaWindowResize)
			}
		}
	}, [])

	menu.forEach(({slug, title}) => {
		let currentUrl = hasWindow() && window.location.pathname
		if (currentUrl) {
			currentUrl = currentUrl.split("/")
			const lastSegment = currentUrl[currentUrl.length - 2]
			if (lastSegment === slug) {
				setCategoryTitle(title)
			}
		}
	})

	return (
		<CategoryMenuContainer className='row'>
			<Col>
				<div className='content'>
					{width <= 767 ? (
						<CategoryFilter
							className='category-filter'
							onClick={() => setShowCategoryFilter(!showCategoryFilter)}>
							<div className='category-name'>
								<span>
									{context} Category:{" "}
									<span className='cat-name'>
										{categoryTitle ||
											(hasWindow() && window.history.state?.title) ||
											"All Webinars"}
									</span>
								</span>
							</div>
							{showCategoryFilter && (
								<CategoryFilterItem>
									{menu.map(category => (
										<CategoryFilterMenuItem
											key={category.id}
											isActive={category.isActive}
											onClick={e => {
												e.preventDefault()
												setCategoryTitle(category.title)
												navigate(`/webinars/category/${category.slug}`, {
													state: {title: category.title}
												})
											}}>
											{category.title}
										</CategoryFilterMenuItem>
									))}
								</CategoryFilterItem>
							)}
						</CategoryFilter>
					) : (
						<>
							{menu.map(category => (
								<RoundButton
									key={category.id}
									isActive={category.isActive}
									onClick={() => navigate(`/webinars/category/${category.slug}`)}>
									{category.title}
								</RoundButton>
							))}
						</>
					)}
				</div>
			</Col>
		</CategoryMenuContainer>
	)
}

export default CategoryMenu

CategoryMenu.propTypes = {
	menus: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			isActive: PropTypes.bool
		}).isRequired
	)
}
