import React from "react"
import PropTypes from "prop-types"
import {navigate} from "gatsby"
import WebinarCard from "./WebinarCard"
import Icon from "../Icon"
import {SectionWrapper, Row, Col} from "@styles/grid"
import {primary} from "@constants/colors"
import {Heading1} from "@styles/text"
import {LinkButton} from "@styles/buttons"
import {getSlugFromTitle} from "@utils"

function CategorySection({Heading, title, blogs, showAll}) {
	return (
		<SectionWrapper bgPrimary className='cat-list-wrap' containerProps={{style: {padding: "50px 0px"}}}>
			<Row className='no-m-ms'>
				<Col>
					<Heading textCenter className='mgn-b-60 cat-heading-title'>
						{title}
					</Heading>
				</Col>
			</Row>
			<Row className='no-m-ms'>
				{blogs.map(blog => {
					const categoryName = title
					return (
						<Col key={blog._uid} className='col-md-6 col-xl-4 mgn-b-30'>
							<WebinarCard
								slug={blog.slug}
								title={blog.title}
								body={blog.body}
								date={blog.date}
								category={categoryName}
								media={blog.media}
								altText={blog.altText}
								// media={blog.image.childImageSharp.fluid}
							/>
						</Col>
					)
				})}
			</Row>
			{showAll && (
				<Row>
					<Col style={{display: "flex", justifyContent: "flex-end"}}>
						<LinkButton onClick={() => navigate(`/webinars/category/${getSlugFromTitle(title)}`)}>
							View all <Icon fill={primary.purple} />
						</LinkButton>
					</Col>
				</Row>
			)}
		</SectionWrapper>
	)
}

export default CategorySection

CategorySection.propTypes = {
	title: PropTypes.string.isRequired,
	showAll: PropTypes.bool,
	Heading: PropTypes.object
}

CategorySection.defaultProps = {
	showAll: true,
	Heading: Heading1
}
