import {useStaticQuery, graphql} from "gatsby"
import {SECTION_TYPES} from "@constants"

export function useWebinarPostData() {
	const data = useStaticQuery(graphql`
		{
			allStoryBlokEntry: allStoryblokEntry(
				filter: {full_slug: {regex: "/webinars/"}}
				sort: {fields: published_at, order: DESC}
			) {
				edges {
					node {
						id
						name
						full_slug
						content
						published_at(fromNow: true)
					}
				}
			}
		}
	`)
	const {allStoryBlokEntry} = data

	const contents = allStoryBlokEntry.edges.map(({node}) =>
		JSON.parse(node.content)
	)

	const webinars = []
	const categoryMap = {}
	let featuredWebinarPost

	contents.forEach(content => {
		const {body} = content

		if (Array.isArray(body)) {
			const webinarPost = body.find(
				item => item.component === SECTION_TYPES.WEBINAR_POST
			)

			if (webinarPost) {
				// Save featured blog
				if (!featuredWebinarPost && webinarPost.featured) {
					featuredWebinarPost = webinarPost
				}
				// Arrange blogs in categories
				webinarPost.category &&
					webinarPost.category.forEach(cat => {
						if (categoryMap[cat]) {
							// Add to list
							categoryMap[cat] = [
								...categoryMap[cat],
								webinarPost
							]
						} else {
							// Create list
							categoryMap[cat] = [webinarPost]
						}
					})

				// Add to blogs
				webinars.push(webinarPost)
			}
		}
	})

	const categories = Object.keys(categoryMap)

	return [categories, categoryMap, featuredWebinarPost, webinars]
}
