import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import Styled from "styled-components"
import {Anchor} from "../Generic"
import {SectionWrapper, Row, Col} from "@styles/grid"
import {Note} from "@styles/text"
import {primary, backgrounds, extended} from "@constants/colors"
import {useStaticQuery, graphql} from "gatsby"
import {getButton, navigateTo} from "@utils"
import Img from "gatsby-image"
import {useFooter} from "../../utils/footer"
import {BirdSeed} from "../Plugin"

import {
	MENU,
	SOCIAL_LINKS,
	LEGAL_INFO,
	toCapitalCase,
	ALL_IN_ONE_PLATFORM,
	ABOUT_UPRISE,
	PRODUCT_PRICING
} from "./utils"

const FooterWrapper = Styled.div`

	.footer__links_wrapper {
		margin-bottom: 30px;

		&:last-of-type {
			margin-bottom: 0;
		}

		.footer__links_title,
		.footer__links_title > p {

			@media screen and (max-width: 768px) {
				font-size: 20px;
			}
		}
	}

  & .footer__right {
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .copy-rights {
      font-weight: normal;
      text-align: center;
    }

	& .button--with-image {
    width: 100%;
    padding: 0px;
  }

    @media (min-width: 1024px) {
      margin: 0px;
    }
  }

  & .footer__links {
    list-style: none;
    padding: 0px;
	margin-bottom: 0;
	color: ${primary.purple};

	@media (max-width: 768px) {
		display: block;
		columns: 2;
		margin-left: -5px;
		margin-right: -5px;
	}

    & li {
	  margin-bottom: 8px;
	  padding-right: 20px;
	//   width: 150px;

	  @media (max-width: 768px) {
		padding: 0 5px; 
	  }

	  &:nth-child(2n+1) {
		  clear: left;
	  }
	}
	
	
  }

  & .footer__company-info {
    & ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }
    & .footer__contact {
      margin-top: 30px;
    }

    & li {
      margin-bottom: 8px;
    }
  }

  & .footer__social-links {
    margin-top: 48px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
	margin-right : 16px;
    @media (min-width: 768px) {
      margin-top: 0px;
	  margin-right : 16px;

      & img {
        margin-left: 10px;
      }
	}

    & img {
      width: 40px;
      height: 40px;
      /* margin-left: 40px; */
	}
	
	@media(max-width: 768px) {
		justify-content: flex-start;
		& img {
			margin-right: 10px;
			width: 32px;
			height: 32px;
		}
	}
  }

  & button {
    margin-right: 16px;

  }

  & a {
    text-decoration: none;
    color: inherit;
  }
`

const FooterLinkTitle = Styled.div`
  margin-bottom: 1rem;
`

const FooterAppBadges = Styled.div`
 display : flex;

`

function Footer({backgroundColor}) {
	const [pageScroll, setPageScroll] = useState(0)
	const footer = useFooter()

	useEffect(() => {
		window.addEventListener("scroll", () => setPageScroll(window.pageYOffset))
		return () => window.removeEventListener("scroll", () => setPageScroll(window.pageYOffset))
	}, [])

	const paddingVertical = 60

	const data = useStaticQuery(graphql`
		{
			googlePlay: file(relativePath: {eq: "play_store_logo_black.png"}) {
				childImageSharp {
					fixed(width: 130) {
						...GatsbyImageSharpFixed
					}
				}
			}
			appStore: file(relativePath: {eq: "app_store_badge.png"}) {
				childImageSharp {
					fixed(width: 130) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)
	const Button = getButton("LINK")

	return (
		<SectionWrapper
			containerFluidProps={{
				className: "container-fluid",
				style: {
					backgroundColor: backgroundColor
				}
			}}
			containerProps={{
				className: "container",
				style: {
					paddingTop: paddingVertical,
					paddingBottom: 42
				}
			}}>
			<FooterWrapper>
				<Row>
					<Col className='col'>
						<Row>
							{footer?.navitems?.map(footer => (
								<div className='col footer__links_wrapper' key={footer._uid}>
									<FooterLinkTitle className='footer__links_title'>
										<Note color={extended.charcoal.one} bold>
											{footer.title}
										</Note>
									</FooterLinkTitle>
									<ul className='footer__links'>
										{footer?.items?.map(item => (
											<li key={item.key}>
												<Note color={primary.purple}>
													<Anchor to={item.link}>{toCapitalCase(item.title)}</Anchor>
												</Note>
											</li>
										))}
									</ul>
								</div>
							))}
							<div className='footer__right'>
								<FooterAppBadges className='footer_app_badges'>
									<Button
										onClick={() =>
											navigateTo(
												" https://play.google.com/store/apps/details?id=co.uprise.swemyss.uprise&hl=en_AU"
											)
										}
										className={`button--with-image`}>
										<Img fixed={data["googlePlay"].childImageSharp.fixed} alt='app store' />
									</Button>
									<Button
										onClick={() =>
											navigateTo(
												" https://apps.apple.com/au/app/uprise-mental-resilience/id1203493898"
											)
										}
										className={`button--with-image`}>
										<Img
											imgStyle={{objectFit: "contain"}}
											fixed={data["appStore"].childImageSharp.fixed}
											alt='app store'
										/>
									</Button>
								</FooterAppBadges>
								<div className='footer__social-links'>
									{SOCIAL_LINKS.map(link => (
										<Anchor
											to={link.link}
											key={link.key}
											target='_blank'
											aria-label={link.key}
											rel='noopener noreferrer'>
											<img
												loading='lazy'
												src={link.label}
												alt={link.key}
												width='40'
												height='40'
											/>
										</Anchor>
									))}
								</div>
								<Note className='copy-rights' color={extended.charcoal.one}>
									© 2019 Uprise Services. All rights reserved.
								</Note>
							</div>
						</Row>
					</Col>
				</Row>
			</FooterWrapper>
			{/* {afterPageLoad && <GAnalytics />} */}
			{pageScroll >= 0 ? <BirdSeed /> : ""}
		</SectionWrapper>
	)
}

export default Footer

Footer.propTypes = {
	backgroundColor: PropTypes.string.isRequired
}
Footer.defaultProps = {
	backgroundColor: backgrounds.fadedPurple
}
