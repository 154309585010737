import React from "react"
import SbEditable from "storyblok-react"
import Components from "../components.js"
import {SectionWrapper, Row, Col} from "@styles/grid"
import {useMobile} from "@utils"
import classnames from "classnames"
import {getFluidGatsbyImage} from "gatsby-storyblok-image"
import Img from "gatsby-image"

function Grid({blok}) {
	const hasPath = blok.columns.some(col => col.component === "path")
	const columns = blok.columns
	const splitScreen = blok.columns_in_row * 1 === 2
	const cols =
		blok.columns_in_row >= 4
			? `col-lg-6 col-xl-${Math.floor(12 / blok.columns_in_row)}`
			: `col-lg-${Math.floor(12 / blok.columns_in_row)}`
	const custom_col = blok.custom_col ? `${blok.custom_col}` : null
	const {
		paddingTop,
		paddingBottom,
		paddingLeft,
		paddingRight,
		borderTop,
		borderBottom,
		isPaddingHorizontal: noPaddingHorizontal,
		paddingHorizontal,
		paddingVertical,
		backgroundColor,
		backgroundImage,
		backgroundPosition,
		backgroundPosition_lg,
		backgroundPosition_md,
		backgroundSize,
		backgroundSize_md,
		minHeight,
		minWidth,
		noColumnPadding,
		alignSelf = "center",
		position,
		last_column_offset,
		first_column_offset_class,
		marginTop,
		containerMarginTop,
		isRowMarginEnabled,
		hideBackgroundImage,
		isContentCenter
	} = blok

	const containerFluid = blok.columns.some(blok => blok.component === "Carousel")
	const isMobile = useMobile()
	const bgFluidImageProps = getFluidGatsbyImage(backgroundImage, {
		minHeight: minHeight
	})
	const rowStyle =
		hasPath && !isRowMarginEnabled
			? {position: "relative", margin: 0}
			: hasPath
			? {postion: "relative"}
			: isRowMarginEnabled && !isRowMarginEnabled
			? {margin: 0}
			: isContentCenter
			? {justifyContent: "center"}
			: {alignItems: "center"}
	return (
		<SbEditable content={blok}>
			<SectionWrapper
				containerProps={{
					className: containerFluid ? "container-fluid" : "container",
					style: {
						paddingTop: paddingTop ? paddingVertical : 0,
						paddingBottom: paddingBottom ? paddingVertical : 0,
						paddingLeft: paddingLeft && !noPaddingHorizontal ? (isMobile ? 24 : paddingHorizontal) : 15,
						paddingRight: paddingRight && !noPaddingHorizontal ? (isMobile ? 24 : paddingHorizontal) : 15,
						marginTop: containerMarginTop ? containerMarginTop : 0
					}
				}}
				containerFluidProps={{
					style: {
						minHeight: `${minHeight}px`,
						position: backgroundImage ? "relative" : "",
						// backgroundImage: `url("${backgroundImage}")`,
						hideBackgroundImageonSmallScreen: hideBackgroundImage,
						backgroundSize: backgroundSize ? backgroundSize : "100% 754px",
						backgroundSizeMd: backgroundSize_md ? backgroundSize_md : "auto",
						backgroundPosition: backgroundPosition,
						backgroundPositionLg: backgroundPosition_lg, //adding this background images for responsive screen. check how it works page
						backgroundPositionMd: backgroundPosition_md,
						backgroundRepeat: "no-repeat",
						borderTopLeftRadius: borderTop ? 10 : 0,
						borderTopRightRadius: borderTop ? 10 : 0,
						borderBottomLeftRadius: borderBottom ? 10 : 0,
						borderBottomRightRadius: borderBottom ? 10 : 0,
						backgroundColor: backgroundColor && backgroundColor.trim(),
						paddingLeft: paddingHorizontal === "0px" || noPaddingHorizontal ? 0 : 15,
						paddingRight: paddingHorizontal === "0px" || noPaddingHorizontal ? 0 : 15,
						marginTop: containerMarginTop ? containerMarginTop : 0
					}
				}}>
				{bgFluidImageProps && (
					<Img
						fluid={bgFluidImageProps}
						alt='background header image'
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%"
						}}
					/>
				)}
				<Row style={rowStyle}>
					{blok.columns.map((blok, index) => {
						//console.log(blok)
						const colOffset =
							last_column_offset && index === columns.length - 1 ? `offset-xl-${last_column_offset}` : ""
						const columnsClass = custom_col ? custom_col : cols
						const first_column_offset =
							first_column_offset_class && index === 0 ? first_column_offset_class : {}
						const colPosition = position === "right" ? "ml-auto" : ""
						return (
							<Col
								key={blok._uid}
								className={classnames(columnsClass, colOffset, first_column_offset, colPosition, {})}
								style={{
									position: blok.component === "path" && "static",
									// alignSelf: blok.component !== "path" && "flex-start",
									padding: noColumnPadding ? 0 : "0 15px",
									marginTop: marginTop ? `${marginTop}px` : ""
								}}>
								{React.createElement(Components(blok.component), {
									key: blok._uid,
									blok: blok,
									splitScreen,
									canGoFullBleed: blok.columns_in_row * 1 === 1,
									...blok
								})}
							</Col>
						)
					})}
				</Row>
			</SectionWrapper>
		</SbEditable>
	)
}

export default Grid
