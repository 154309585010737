import {useEffect, useState} from "react"
import {logger} from "./index"
// JSON-LD markup generated by Google Structured Data Markup Helper
function generateArticleJSONLD(data) {
	return {
		"@context": "http://schema.org",
		"@type": "Article",
		name: data.name,
		headline: data.name,
		author: {
			"@type": "Person",
			name: data.author
		},
		datePublished: data.datePublished,
		dateModified: data.datePublished,
		image: data.articleImage,
		articleSection: data.articleSection,
		articleBody: data.articleBody,
		url: data.url,
		mainEntityOfPage: "https://uprise.co/blog",
		publisher: {
			"@type": "Organization",
			name: data.publisher,
			logo: data.articleImage
		}
	}
}

function generateOrganisationJSONLD() {
	return {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Uprise",
		image:
			"https://img2.storyblok.com/900x378/smart/filters:quality:(100):format:(webp)/f/65490/2691x1131/4e78b5c181/header-3x.png",
		telephone: "1300 209 371",
		email: "team@uprise.co",
		address: {
			"@type": "PostalAddress",
			streetAddress: "11 York Street",
			addressLocality: "Sydney",
			addressRegion: "NSW",
			addressCountry: "Australia",
			postalCode: "2000"
		},
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: {
				"@type": "DayOfWeek",
				name: "All"
			},
			opens: "2015-07-27T15:30",
			closes: "2015-07-27T15:30"
		},
		url: "https://uprise.co/about"
	}
}

function getJSONLD(type, data) {
	switch (type) {
		case "BLOG":
			return generateArticleJSONLD(data)
		case "HOME":
			return generateOrganisationJSONLD(data)
		default:
			return generateOrganisationJSONLD(data)
	}
}

function useJSONLD(type, data) {
	const [isScriptLoaded, setIsScriptLoaded] = useState(false)

	useEffect(() => {
		const alreadyLoaded = document.getElementById("structure_data_schema")

		if (!isScriptLoaded && !alreadyLoaded) {
			setIsScriptLoaded(true)
			const script = document.createElement("script")
			document.body.appendChild(script)
			script.onload = () => {
				logger("Script loaded successfully!")
			}
			script.onerror = () => {
				logger("Error occurred while loading script")
			}
			script.id = `structure_data_schema`
			script.type = "application/ld+json"
			script.text = JSON.stringify(getJSONLD(type, data))
		}
	}, [type, data, isScriptLoaded])
}

export {useJSONLD}
