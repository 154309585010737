import React from "react"
import Components from "../components.js"
import {SectionWrapper, Row, Col} from "@styles/grid"
import {useMobile} from "@utils"

const Column = ({number_of_columns}) => {
	const cols =
		number_of_columns >= 4
			? `col-lg-6 col-xl-${Math.floor(12 / number_of_columns)}`
			: `col-lg-${Math.floor(12 / number_of_columns)}`
	return <Col className={cols}></Col>
}

export default Column
